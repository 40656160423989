export const en = {

    //services Types
    Integration: "Integration",
    Tech_Direction: "Technical Direction",
    Development: "Development",
    Automation: "Automation",

    //portfolio details page
    References: "References",
    Reference: "Reference",

    //viktor&Rolf
    var_01: "As an employee of Rodeo FX, I contributed to the development and implementation of the 'Fashion Statements' exhibition, an innovative collaboration between Rodeo FX, Quebec curator Thierry-Maxime Loriot, and the renowned fashion duo Viktor&Rolf.", 
    var_02: "Displayed at the Kunsthalle München Museum in Munich. This exhibition invites you to explore a realm where fashion intersects with art, engaging the senses with optical illusions and immersive audio design, celebrating the 30-year creative journey of Viktor Horsting and Rolf Snoeren.",

    //destination bc
    bc_title: "Destination British Columbia",
    bc_01: "As an employee of Rodeo FX, we crafted an interactive experience showcasing the beauty of British Columbia in bustling urban areas: London and Sydney.",
    bc_02: "BC Wall of Wonder:",
    bc_03: "Running from January 16 to February 29, 2024, in the UK, and February 6 to March 29, 2024, in Australia, the BC Wall of Wonder featured a 26-foot wide, 8-foot high screen. Installed on busy city streets, it transported pedestrians from their daily commute into the Pacific rainforest and the Canadian Rockies.",
    bc_04: "This captivating display brought a piece of Canada's natural splendor to urban settings, inviting passersby to immerse themselves in the diverse and beautiful landscapes of British Columbia.",

    //exact
    exact_title: "Exact numérique",
    exact_01: "The Exact is a digital form that replaces paper forms for the evaluation of patients who have suffered a head injury. Validated by the scientific community, this test facilitates the rapid and accurate collection of data. The information collected is anonymized to protect patient confidentiality and is then integrated into a database. This database is used for studies and research, thus contributing to the improvement of treatments and management of head trauma.",

    //jeunes inventeurs
    ji_title: "Teens: Creative Minds",
    ji_01: "As part of a traveling exhibition for the J. Armand Bombardier Museum, and as an employee at Rodeo FX, we have showcased the innovative ideas of young Canadian adolescents. This unique exhibition celebrates a dozen young talents who, over the past 100 years, have pioneered innovations to improve their world.",

    //avy
    avy_title: "Avy The Elf",
    avy_01: "To refresh the Christmas experience at Avalon Mall, and as an employee at Rodeo FX, we have reinvented the traditional visit to Santa Claus by introducing Avy the Elf. This playful experience invites children to dance and have fun to earn their gifts, adding a festive and interactive touch to the magic of the holiday season.",

    //callisto
    cal_01: "We have created an immersive experience to generate buzz at Comic-Cons around the world as well as in the offices of Liquid Arcade. The installation places visitors in a scan simulation in front of a screen that displays their silhouette as a skeleton in real-time. The horror atmosphere intensifies as they are crushed by a monster. Participants' reactions are filmed and their video is sent at the end of the experience. This attraction features characters from the game Callisto Protocol.",

    //cosmo
    cos_01: "As an employee at Rodeo FX, to modernize the simulation experience at the Cosmodome Laval, we reinvented the Mission Cosmo. In collaboration with the agency Créo, we developed an interactive activity that allows the whole family to immerse themselves in a space mission simulation.",
    cos_02: "We contributed to designing the activity's interfaces and implemented a communication process to ensure the smooth execution of the simulation. To enhance the experience, renowned astronaut David Saint-Jacques recorded introduction and conclusion videos for the activity. To complement the playful aspect of the simulation, we created a motion design animation framework that accompanies the videos, with the animations produced by the VFX team.",

    //tdgq
    tdgq_title: "Tower of Montreal Port",
    tdgq_01: "Discover the permanent installation at the Montreal Port Tower Interpretation Center, showcasing the city's most beautiful attractions. As employees at Rodeo FX, we created these installations, including interactive experiences, welcoming robots, and musical balloons with a soundtrack produced by the OMS. This immersive exhibit celebrates the diversity and beauty of Montreal in an innovative and captivating way.",

    //insectarium
    ins_title: "Throw the eyes",
    ins_01: "Throw the Eyes is an experience that demonstrates how insects see our world",


    //Jobel
    jobel_title: "Dans le fleuve",
    jobel_01: "As an employee of RodeoFX, I participated in the creation, development, and integration of an immersive experience about the St. Lawrence River in an underground corridor in Montreal. This multisensory installation combines videos, sounds, and interactivity to raise awareness among passersby about the importance of the river in Montreal's identity. It contributes to an experience that invites visitors to walk, listen, observe, and learn about this vital element of the urban ecosystem.",

    //Bouclair
    bouclair_title: "Bouclair",
    bouclair_01: "As a member of the RodeoFX team, I contributed to the development and integration of a video playback and interactive media system for Bouclair's two impressive storefronts in Griffintown. This system selects and displays content according to a predefined schedule, while incorporating interactive features that respond to the presence and movements of passersby. The uniqueness of this installation lies in its ability to adapt content based on seasons, time of day, and even real-time weather conditions, thus offering a dynamic experience for pedestrians.",

    //contact
    contact_title: "LET'S TALK",
    phone: "Phone",
    email: "Email",
    location: "Location",
    map_title: "24/7 ANYTIME",

    cs: "To be unveiled soon",

    //simons
    simons_title: "The Forest of Wonders at Maison Simons",
    simons_01: "Animated characters, light shows, and an original soundtrack... As the technical director at Rodeo FX, I oversaw the technical implementation of this exceptional Christmas window display, in collaboration with Hublot51 and Solotech.",
    simons_02: "Eighteen projectors and eight synchronized computers delivered an immersive visual and auditory experience, capturing the magic of the holiday season through a mesmerizing play of illusions and animations. A display where innovation and art come together to enchant the Maison Simons.",

    //error
    error_title: "The page you are looking for was not not found. it might have been moved or delete",
    error_back: "Back To Home",

    //services
    tech_direction: "TECHNICAL DIRECTION",
    web_dev: "WEB DEVELOPMENT",
    engine_dev: "DEVELOPMENT ON MULTIPLES PLATFORMS",
    modeling: "3D MODELING",
    networking: "NETWORKING",
    installation: "INSTALLATION",
    more: "& MORE...",

    tech_direction1: "Creation of plans, technical drawings, diagrams detailing the placement of products, electrical connections, and control signals, as well as conducting projection studies and more.",
    modeling1: "Design 3D models and custom parts creation for various applications using Fusion 360.",
    web_dev1: "JavaScript, TypeScript, React, as well as Meteor and MySQL.",
    engine_dev1: "TouchDesigner, MaxMsp, Unreal, Processing, Arduino, video playback (MadMapper, Resolume), Automation.",
    networking: "Networking",
    installation1: "Count on me for the installation and delivery of your installations!",

    //about section
    catchPhrase: "WOULD YOUR MOTHER BE PROUD?",
    about_me: "Based in Montreal, Jacob Lacasse is a technical director, creative technologist, and a passionate enthusiast of new media. Following his studies in interactive media, he developed his skills in various fields such as advertising, exhibitions, and permanent installations. Through these experiences, he has collaborated with and earned the trust of key players in the creative industry such as RodeoFX and Kunsthalle Munich, as well as other renowned clients like the Montreal Port Authority. By working alongside artists and creative thinkers, Jacob strives to bring his collaborators' visions to life by delivering high-quality products.",
    //footer
    next: "Next",
    prev: "Prev",
    rights: "ALL RIGHT RESERVED",

    link_request: "Link upon request",

    //nav
    about_me_title: "ABOUT",

    login: "Login",
    password: "Password",
    showpwd: "Show Password",
    enter: "Enter",


} 