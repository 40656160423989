export const fr = {

    //services Types
    Integration: "Intégration",
    Tech_Direction: "Direction technique",
    Development: "Développement",
    Automation: "Automatisation",

    //portfolio details page
    References: "Références",
    Reference: "Référence",

    //viktor&Rolf
    var_01: "En tant qu'employé de Rodeo FX, j'ai contribué au développement et à la mise en œuvre de l'exposition « Fashion Statements », une collaboration innovante entre Rodeo FX, le conservateur québécois Thierry-Maxime Loriot et le duo de créateurs de mode renommés Viktor&Rolf.",
    var_02: "Présentée au Kunsthalle München à Munich, cette exposition vous invite à explorer un univers où la mode croise l'art, éveillant les sens avec des illusions d'optique et une conception sonore immersive, tout en célébrant les 30 ans de parcours créatif de Viktor Horsting et Rolf Snoeren.",

    //destination bc
    bc_title: "Destination Colombie-Britanique",
    bc_01: "En tant qu'employés de Rodeo FX, nous avons créé une expérience interactive mettant en valeur la beauté de la Colombie-Britannique dans les grandes villes : Londres et Sydney.",
    bc_02: "BC Wall of Wonder:",
    bc_03: "Du 16 janvier au 29 février 2024 au Royaume-Uni, et du 6 février au 29 mars 2024 en Australie, le Mur de Merveilles de la CB présentait un écran de 8 mètres de large sur 2,4 mètres de haut. Installé dans les rues animées des villes, il transportait les passants de leur trajet quotidien vers la forêt pluviale du Pacifique et les Rocheuses canadiennes.",
    bc_04: "Cette installation captivante a apporté un morceau de l’éclat naturel du Canada dans des environnements urbains, invitant les passants à s’immerger dans les paysages divers et magnifiques de la Colombie-Britannique.",

    //exact
    exact_title: "Exact numérique",
    exact_01: "L'Exact Numérique est un formulaire numérique qui remplace les formulaires papier pour l'évaluation des patients ayant subi un traumatisme crânien. Validé par la communauté scientifique, ce test facilite la collecte rapide et précise des données. Les informations recueillies sont anonymisées pour protéger la confidentialité des patients et sont ensuite intégrées dans une base de données. Cette base de données est utilisée pour des études et recherches, contribuant ainsi à l'amélioration des traitements et de la gestion des traumatismes crâniens.",

    //jeunes inventeurs
    ji_title: "Ados : cerveaux inventifs",
    ji_01: "Dans le cadre d'une exposition itinérante pour le Musée J. Armand Bombardier, en tant qu'employé chez Rodeo FX, nous avons mis en vedette les idées innovantes de jeunes adolescents canadiens. Cette exposition unique célèbre une douzaine de jeunes talents qui, au cours des 100 dernières années, ont innové pour améliorer leur monde.",

    //avy
    avy_title: "Avy l'elfe",
    avy_01: "Pour renouveler l'expérience de Noël au Avalon Mall, et en tant qu'employé chez Rodeo FX, nous avons réinventé la traditionnelle visite au Père Noël en introduisant Avy l'elfe. Cette expérience ludique invite les enfants à danser et s'amuser pour mériter leurs cadeaux, ajoutant une touche festive et interactive à la magie des fêtes.",

    //callisto
    cal_01: "Nous avons créé une expérience immersive pour générer un buzz dans les Comic-Con du monde entier ainsi que dans les bureaux de Liquid Arcade. L'installation place les visiteurs dans une simulation de scan devant un écran qui affiche leur silhouette sous forme de squelette en temps réel. L'ambiance d'horreur s’accentue en étant écrasé par un monstre. Les réactions des participants sont filmées et leur vidéo est envoyée à la fin de l'expérience. Cette attraction met en vedette les personnages du jeu Callisto Protocol.",

    //cosmo
    cos_01: "Comme employé chez Rodéo FX, pour moderniser l’expérience de simulation du Cosmôdome de Laval, nous avons réinventer la Mission Cosmo. En collaboration avec l'agence Créo, nous avons développé une activité interactive qui permet à toute la famille de plonger dans une simulation de mission spatiale.",
    cos_02: "Nous avons contribué à la conception des interfaces de l'activité et mis en place un processus de communication pour assurer le bon déroulement de la simulation. Pour enrichir l'expérience, l'astronaute renommé David Saint-Jacques a enregistré des vidéos d'introduction et de conclusion de l'activité. Pour compléter l'aspect ludique de la simulation, nous avons créé un cadre d'animations en motion design qui accompagne les vidéos, les animations étant réalisées par l'équipe VFX.",

    //tdgq
    tdgq_title: "Tour du port de Montréal",
    tdgq_01: "Découvrez l'installation permanente au Centre d'interprétation de la Tour du Port de Montréal, mettant en valeur les plus beaux attraits de la ville. En tant qu'employés chez Rodeo FX, nous avons créé ces installations, incluant des expériences interactives, des robots accueillants, et des ballons musicaux avec une trame sonore produite par l'OMS. Cette exposition immersive célèbre la diversité et la beauté de Montréal de manière innovante et captivante.",
    
    //insectarium
    ins_title: "À travers les yeux",
    ins_01: "À travers les yeux est une expérience qui démontre la façon dont les insectes voient notre monde.",

    //Jobel
    jobel_title: "Dans le fleuve",
    jobel_01: "En tant qu'employé de RodeoFX, j'ai participé à la création, le développement et l’intégration d'une expérience immersive sur le fleuve Saint-Laurent dans un corridor souterrain de Montréal. Cette installation multisensorielle combine vidéos, sons et interactivité pour sensibiliser les passants à l'importance du fleuve dans l'identité montréalaise. contribuant ainsi à une expérience qui invite les visiteurs à marcher, écouter, observer et s'informer sur cet élément vital de l'écosystème urbain.",
    
    //Bouclair
    bouclair_title: "Bouclair",
    bouclair_01: "En tant que membre de l'équipe RodeoFX, j'ai contribué au développement et à l'intégration d'un système de diffusion vidéo et de médias interactifs pour les deux vitrines imposantes de Bouclair à Griffintown. Ce système sélectionne et diffuse les contenus selon une programmation prédéfinie, tout en intégrant des fonctionnalités interactives réagissant à la présence et aux mouvements des passants. La particularité de cette installation réside dans sa capacité à adapter le contenu en fonction des saisons, de l'heure de la journée, et même des conditions météorologiques en temps réel, offrant ainsi une expérience dynamique pour les passants.",

    //simons
    simons_title: "La Forêt des Merveilles à la Maison Simons",
    simons_01: "Personnages animés, jeux de lumières, bande sonore originale... En tant que directeur technique chez RodeoFX, j’ai supervisé la mise en œuvre technique de cette vitrine de Noël exceptionnelle, en collaboration avec Hublot51 et Solotech.",
    simons_02: "18 projecteurs et 8 ordinateurs synchronisés pour offrir une expérience visuelle et sonore immersive, capturant la magie des fêtes dans un jeu d'illusions et d'animations captivantes. Une vitrine où innovation et art s’unissent pour émerveiller la Maison Simons.",

    //error
    error_title: "La page que vous recherchez n'a pas été trouvée. Elle a peut-être été déplacée ou supprimée.",
    error_back: "Retour à l'accueil",

    //contact
    contact_title: "N'hésitez pas à me contacter",
    phone: "Téléphone",
    email: "Courriel",
    location: "Où me trouver",
    map_title: "En tout temps",

    //services
    tech_direction: "DIRECTION TECHNIQUE",
    web_dev: "DÉVELOPPEMENT WEB",
    engine_dev: "DÉVELOPPEMENT SUR PLUSIEURS PLATEFORMES",
    modeling: "MODÉLISATION 3D",
    networking: "ADMINISTRATION RÉSEAUX",
    installation: "INSTALLATION",
    more: "& PLUS...",

    tech_direction1: "Création de plans, dessins techniques, schémas détaillant la localisation des produits, les branchements électriques et les signaux de contrôle, réalisation d'études de projection et plus.",
    modeling1: "Conception de modèles 3D et création de pièces sur mesure pour diverses applications avec Fusion 360.",
    web_dev1: "JavaScript, TypeScript, React ou encore Meteor et MySQL.",
    engine_dev1: "TouchDesigner, MaxMsp, Unreal, Processing, Arduino, playback vidéo (MadMapper, Resolume), Automatisation.",
    networking1: "",
    installation1: "Comptez sur moi pour l'installation et la livraison de vos installations!",

    //about section
    catchPhrase: "EST-CE QUE TA MÈRE SERAIT FIÈRE?",
    about_me: "Basé à Montréal, Jacob Lacasse est directeur technique, technologue créatif et un passionné de nouveaux médias. À la suite de ses études en médias interactifs, il a développé ses compétences dans divers domaines, tels que la publicité, les expositions et les installations permanentes. Grâce à ces expériences, il a collaboré et gagné la confiance d'acteurs de l'industrie créative tels que RodeoFX et Kunsthalle Munich, ainsi que d'autres clients renommés comme l'Autorité portuaire de Montréal. En collaborant avec des artistes et des penseurs créatifs, Jacob s'efforce de réaliser la visions de ses collaborateurs en livrant des produits de qualité.",

    cs: "Dévoilé prochainement",
    //footer
    next: "Suivant",
    prev: "Précédent",
    rights: "Tous droits réservés",

    link_request: "Lien sur demande",

    //nav
    about_me_title: "À PROPOS",

    login: "Connexion",
    password: "Mot de passe",
    showpwd: "Afficher le mot de passe",
    enter: "Entrer",

    
} 